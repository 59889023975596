document.addEventListener('DOMContentLoaded', async () => {
  const inputsWithMask = document.querySelectorAll<HTMLInputElement>('input[data-mask]');

  if (!inputsWithMask.length) return;

  const { default: IMask } = await import('imask');

  inputsWithMask.forEach(input => {
    if (!input.dataset.mask) return;

    const options = resolveMaskOptions(input.dataset.mask);

    IMask(input, options);
  });

  function resolveMaskOptions (key : string) : IMask.AnyMaskedOptions {
    switch (key) {
      case 'date':
        return {
          mask: 'DD.`MM.`YYYY',
          lazy: false,

          blocks: {
            YYYY: {
              mask: IMask.MaskedRange,
              from: 1900,
              to: 2100,
              placeholderChar: 'y',
            },

            MM: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 12,
              placeholderChar: 'm',
            },

            DD: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 31,
              placeholderChar: 'd',
            },
          },
        };
      case 'tel':
        return {
          mask: '+PHONE',

          blocks: {
            PHONE: {
              mask: /^[0-9() ]{0,25}$/,
            },
          },
        };
      default:
        return {
          mask: key,
        };
    }
  }
});

export {};
