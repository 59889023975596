import './style.scss';
import Cookies from 'js-cookie';

document.addEventListener('DOMContentLoaded', () => {
  if (Cookies.get('cookie_consent') === '1') writeCookieConsent();

  const container = document.querySelector<HTMLElement>('#cookie_disclaimer');

  if (!container) return;

  container.querySelector('button')?.addEventListener('click', () => {
    writeCookieConsent();
    container.style.display = 'none';
  });
});

function writeCookieConsent (): void {
  Cookies.set('cookie_consent', '1', { expires: 365, secure: location.protocol === 'https:' });
}
